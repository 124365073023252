<template>
  <div class="form" :class="{ 'bg-app': !dialog, 'vh-100': !dialog }">
    <v-container fluid class="px-0 h-100 d-flex flex-column">
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-row v-if="hasForm" class="flex-grow-1">
        <v-col class="px-2">
          <v-card flat class="transparent">
            <div v-if="getHeaderImageTag(form.tags)" class="header-image">
              <v-img class="grid-header-img" :src="getHeaderImageTag(form.tags)" style="max-height: 240px"></v-img>
            </div>
            <div class="form-wrap" id="formwrapper">
              <div class="form-title">
                <v-card-title class="form-title-name text-h5 px-4 pt-4 pb-2">{{ form.name }}</v-card-title>
                <v-card-subtitle v-if="form.description" class="text-subtitle-1 px-4">
                  {{ form.description }}
                </v-card-subtitle>
              </div>
              <v-form ref="sectionForm" v-model="formValid" lazy-validation>
                <form-viewer
                  :schema="schema"
                  ref="form"
                  :form="form"
                  :formId="formId"
                  :dialog="dialog"
                  :isAssignment="false"
                  @validate="validate"
                />
              </v-form>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <h4 v-else-if="showNoFormMessage" class="pa-4">{{ $t("v_form.t_no_form") }}</h4>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import FormViewer from "@/components/forms/FormViewer.vue";
import { AssetService } from "@/services";

export default {
  name: "FormPlanner",
  metaInfo: {
    title: "Form",
  },
  components: {
    FormViewer,
  },
  mixins: [Helpers],
  props: {
    type: {
      type: String,
      default: "forms",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: [Number, String],
      default: null,
    },
    plannerId: {
      type: [Number, String],
      default: null,
    },
    plannerAssignmentId: {
      type: [Number, String],
      default: null,
    },
    plannerAssignmentDueDateId: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    hasForm() {
      return !!this.form;
    },
    showNoFormMessage() {
      return this.loaded && !this.hasForm;
    },
  },
  watch: {
    formId: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.$online) {
          this.init();
        }
      },
    },
    form: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.dialog) {
          this.$emit("update:formTitle", newVal.name);
        }
      },
    },
  },
  data: () => ({
    loaded: false,
    form: null,
    schema: [],
    formValid: true,
    tempFiles: [],
  }),
  methods: {
    ...mapActions("forms", ["getFormOpen", "saveFormSubmission"]),
    validate(submit, isAssignment, activeSection) {
      const dirtyInputs = this.getDirtyInputs(activeSection);
      this.updateRequiredFields(dirtyInputs);

      if (dirtyInputs.length === 0) {
        this.handleValidForm(submit, isAssignment, activeSection);
      } else {
        this.showRequiredAlert();
      }
    },

    getDirtyInputs(activeSection) {
      return this.schema.filter((input) => this.isInputDirty(input, activeSection));
    },

    isInputDirty(input, activeSection) {
      const isActiveSection = activeSection == input.section || !input.section;
      const isRequiredAndEmpty = input.answer?.required && this.isValueEmpty(input.answer?.value);
      const isRequiredSignatureEmpty = input.ui.el == "signature" && input.required && input.value == "";
      const isRequiredFileEmpty = input.ui.el == "file" && input.required && this.isFileEmpty(input);

      return isActiveSection && (isRequiredAndEmpty || isRequiredSignatureEmpty || isRequiredFileEmpty);
    },

    isValueEmpty(value) {
      return value === null || value === "" || (Array.isArray(value) && !value.some((v) => v == true));
    },

    isFileEmpty(input) {
      return (input.file == null || input.file == "") && input.value == null;
    },

    updateRequiredFields(dirtyInputs) {
      dirtyInputs.forEach((input) => {
        this.$refs.form.$refs[input.key][0].querySelector(".entry-section").classList.add("show-required");
      });
    },

    handleValidForm(submit, isAssignment, activeSection) {
      this.$refs.form.$refs["alert-check-required"].$el.classList.remove("show-alert");
      if (submit === false) {
        this.saveForm(false);
      } else if (submit === true) {
        this.saveForm(true);
      } else {
        this.handleSectionNavigation(isAssignment, activeSection);
      }
    },

    handleSectionNavigation(isAssignment, activeSection) {
      if (!isAssignment) {
        this.$refs.form.activeSection = activeSection + 1;
      } else {
        this.saveSection(activeSection);
      }
    },

    showRequiredAlert() {
      this.$refs.form.$refs["alert-check-required"].$el.classList.add("show-alert");
    },

    saveForm(doSubmit) {
      this.saveAnswers(doSubmit);
    },

    saveSection(activeSection) {
      this.saveAnswers(false, activeSection);
    },

    submitForm() {
      if (this.$refs.form.validateForm()) {
        this.saveAnswers();
      }
    },

    saveInputs(answers) {
      let resp = [];
      Object.keys(answers).forEach((key) => {
        if (key != "files") {
          let params = {};
          params.code = key;
          params.userResponse = Array.isArray(answers[key]) ? JSON.stringify(answers[key]) : answers[key];
          resp.push(params);
        }
      });
      return resp;
    },

    async saveAnswers() {
      let answers = this.$refs.form.collectAnswers();
      let fileCount = 0;
      this.showLoadBar();
      Object.keys(answers).forEach(async (key) => {
        if (key == "files") {
          fileCount = answers[key].length;
          const promises = answers[key].map((item) => this.fileUpload(item.key, item.value));
          Promise.all(promises).then((r) => {
            let resp = r.flat();
            let inputs = this.saveInputs(answers);
            resp.push(...inputs);
            this.submitFormSubmission(resp);
          });
        }
      });
      if (fileCount == 0) {
        let resp = this.saveInputs(answers);
        this.submitFormSubmission(resp);
      }
    },

    async submitFormSubmission(responses) {
      const getValidNumber = (value) => {
        const num = Number(value);
        return !isNaN(num) ? num : null;
      };

      const plannerId = getValidNumber(this.plannerId);
      const plannerAssignmentId = getValidNumber(this.plannerAssignmentId);
      const plannerAssignmentDueDateId = getValidNumber(this.plannerAssignmentDueDateId);

      let payload = {
        submit: true,
        fields: responses,
        revisionId: this.form.formRevisionId || this.form.latestRevisionId,
        id: this.formId,
      };

      if (plannerId !== null) {
        payload.plannerId = plannerId;
      }

      if (plannerAssignmentId !== null) {
        payload.plannerAssignmentId = plannerAssignmentId;
      }

      if (plannerAssignmentDueDateId !== null) {
        payload.plannerAssignmentDueDateId = plannerAssignmentDueDateId;
      }

      try {
        const result = await this.saveFormSubmission(payload);
        if (result) {
          this.handleSubmissionSuccess();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        this.handleSubmissionError();
      } finally {
        this.hideLoadBar();
      }
    },

    handleSubmissionSuccess() {
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_success_form_submitted"),
        color: "success",
        timeout: 3000,
      });
      this.$emit("close");
      this.$emit("submit");
    },

    handleSubmissionError() {
      this.$snackbar.showMessage({
        content: this.$t("c_snackbar.t_error_form_submission"),
        color: "error",
        timeout: 3000,
      });
    },

    fileUpload: async function (key, file) {
      const uploadedAsset = await this.imageUpload(file, key);
      return {
        code: uploadedAsset.code,
        userResponse: uploadedAsset.key,
      };
    },

    imageUpload: async function (file, fieldId) {
      const payload = {
        name: file.name,
        description: "",
        originalFileName: file.name,
        mimeType: file.type,
        cacheable: true,
        permitCompany: true,
      };
      const result = await AssetService.uploadAssetBegin(payload);
      const { assetId, uploadUrl, assetGuid: key } = result.data;

      if (assetId && uploadUrl && key) {
        const r = await fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type },
        });

        if (r.ok) {
          await AssetService.uploadAssetComplete({ assetId });
          this.tempFiles.push({ assetId, key: fieldId });
          return { code: fieldId, key };
        }
      }
      throw new Error("Failed to upload file");
    },

    getHeaderImageTag(tags) {
      if (!tags) return "";
      const headerTag = tags ? tags.split(",")[1] : "";
      if (headerTag) {
        return `https://clockworksafety-public.s3.us-west-2.amazonaws.com/form-headers/${
          headerTag.split("header-")[1]
        }`;
      }
      return "";
    },

    async init() {
      this.loaded = false;
      if (this.formId) {
        try {
          const response = await this.getFormOpen(this.formId);
          this.form = response.data;
          this.schema = [...JSON.parse(this.form.serializedFormContent)];
        } catch (error) {
          console.error("Error loading form:", error);
        } finally {
          this.loaded = true;
        }
      } else {
        this.loaded = true;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.form {
  .theme--dark {
    color: #eee !important;
    &.error--text {
      color: #fa4646 !important;
      caret-color: #b71c1c !important;
    }
  }
  .container {
    max-width: 100%;
  }
  .form-wrap {
    padding-bottom: 15px;
  }
  .header-image {
    margin: -7px -9px 8px;
  }
  .form-title-name {
    word-break: keep-all;
  }
}
</style>
