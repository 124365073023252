<template>
  <v-dialog class="form-dialog" v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="d-flex flex-column" height="100vh">
      <v-toolbar dark color="green darken-2" class="flex-grow-0">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="flex-grow-1 overflow-y-auto pa-0">
        <v-container fluid class="fill-height pa-0">
          <v-row justify="center" class="fill-height ma-0">
            <v-col cols="12" sm="11" md="10" lg="8" xl="6" class="pa-0">
              <FormPlanner
                :formId="formId"
                :plannerId="plannerId"
                :plannerAssignmentId="plannerAssignmentId"
                :plannerAssignmentDueDateId="plannerAssignmentDueDateId"
                :dialog="true"
                @close="closeDialog"
                @save="handleSave"
                @update:formTitle="formTitle = $event"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormPlanner from "@/components/forms/FormPlanner.vue";

export default {
  name: "FormDialog",
  components: {
    FormPlanner,
  },
  data() {
    return {
      dialog: false,
      formId: null,
      plannerId: null,
      plannerAssignmentId: null,
      plannerAssignmentDueDateId: null,
      formTitle: "",
    };
  },
  methods: {
    openDialog(formId, plannerId, plannerAssignmentId, plannerAssignmentDueDateId) {
      this.formId = formId;
      this.plannerId = plannerId;
      this.plannerAssignmentId = plannerAssignmentId;
      this.plannerAssignmentDueDateId = plannerAssignmentDueDateId;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.formId = null;
      this.plannerId = null;
      this.plannerAssignmentId = null;
      this.plannerAssignmentDueDateId = null;
    },
    handleSave() {
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss">
.form-dialog {
  .v-dialog--fullscreen .v-card {
    height: 100vh !important;
  }
}
</style>
